<template>
    <a-modal title="导出展位图" v-model:visible="show" width="100%" @cancel="close" wrapClassName="full-modal" :footer="null">
      <div class="headbox">
        <div class="item">
          <span style="margin-right:4px;">背景图</span>
          <a-switch
              size="small"
              @change="bgSwitch"
              v-model:checked="options.bg"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item" v-if="isAdmin">
          <span style="margin-right:4px;">展位状态</span>
          <a-switch
              size="small"
              @change="bgSwitch"
              v-model:checked="options.status"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <!-- <div class="item" v-if="isAdmin">
          <span style="margin-right:4px;">显示品牌</span>
          <a-switch
              size="small"
              @change="bgSwitch"
              v-model:checked="options.brand"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div> -->
        <!-- <div class="item">
          <span style="margin-right:4px;">标尺</span>
          <a-switch
              size="small"
              @change="rulerSwitch"
              v-model:checked="options.ruler"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div> -->
        <div class="item">
          <span style="margin-right:4px;">网格</span>
          <a-switch
              size="small"
              @change="gridSwitch"
              v-model:checked="options.grid"
              active-color="#247fce"
              inactive-color="#ccc">
          </a-switch>
        </div>
        <div class="item download" @click="download" style="text-decoration: underline; color:blue">点击下载</div>
        
      </div>
      <div class="export-content">
        <a id="link"></a>
        <canvas ref="can" id="exportCanvas" :class="{'hasBg':options.bg}"></canvas>
      </div>
    </a-modal>
</template>

<script>
//   import { Loading } from 'element-ui';
import {useGetMapStyle,loadImage,getBackgroundByIndex,getAreaImgByIndex} from '@/use/diy-use.js'
import store from '@/store'


  export default {
    components:{},
    props:{
    //   mapStyle:{type:Object, default:{}},
      exportVisible:{type:Boolean, default:false}
    },
    data(){
      return {
        options:{
          brand:true,
          bg:true,
          status:true,
          ruler:true,
          grid:true
        },
        canvas: null,
        // cpadding:[350,240,120,240],
        cpadding:[480,240,120,240],
        // cpadding:[0,0,0,0],
        ctx:null,
        innerTop:0,
        innerLeft:0,
        innerWidth:0, //去掉左右边距，去掉背景图内边距，绘图区域显示像素
        innerHeight:0,
        height:'',
        show : false,
        mapStyle:{}
      }
    },
    computed:{
      lists(){return this.$store.state.lists},
      selectCategory(){return this.$store.state.selectCategory},
      isAdmin(){return (store.state.user.role_id===1||store.state.user.role_id===2)}
    },
    mounted(){
      // window.onresize = () => {
      //   this.show && this.initCalc();
      // }
      // this.mapStyle = useGetMapStyle(this.$store.state.selectCategory);
    },
    methods:{
      initCalc(){
          this.mapStyle = useGetMapStyle(this.$store.state.selectCategory);
          if(!this.canvas){
            this.canvas = document.getElementById("exportCanvas");
            this.ctx = this.canvas.getContext('2d');
          }
          const {canvas} = this;
          const winW = window.innerWidth;
          const winH = window.innerHeight;
          const widthA4 = 3508; //a4纸长边分辨率
          const cp = this.cpadding;

          // let {width,height} = this.mapStyle.outer;
          let width,height;

          //带背景图片
          if(this.options.bg){
            width = this.mapStyle.outer.width;
            height = this.mapStyle.outer.height;
          }else{
            width = this.mapStyle.inner.width;
            height = this.mapStyle.inner.height;
          }
          const hwRadio = height.replace('px','')/width.replace('px','');

          //60为画板左右边距之和，150为画板上下边距之和
          // canvas.style.width = winW-60+"px";
          // canvas.style.height = (winW-60)*hwRadio+'px'
          // if((winW-60)*hwRadio>winH-140){
          //   canvas.style.height =  winH-140+"px";
          //   canvas.style.width = (winH-140)/hwRadio+'px';
          // }

          canvas.width = widthA4+cp[1]+cp[3];
          canvas.height = (height.replace('px','')/width.replace('px','')*widthA4)+cp[0]+cp[2];

          let radio = widthA4/this.mapStyle.outer.width.replace("px",'');

          if(this.options.bg){
            const {top,left,width,height} = this.mapStyle.inner;
            this.innerTop = top.replace("px",'')*radio;
            this.innerLeft = left.replace("px",'')*radio;
            this.innerWidth = width.replace("px",'')*radio; 
            this.innerHeight = height.replace("px",'')*radio; 
          }else{
            this.innerTop = 0;
            this.innerLeft = 0;
            this.innerWidth = canvas.width-cp[1]-cp[3];
            this.innerHeight = canvas.height-cp[0]-cp[2]; 
          }
          this.draw();
      },
      async draw(){
        const {canvas,ctx} = this;
        const {picture,width,height} = this.selectCategory;
        const cp = this.cpadding;
        ctx.fillStyle="#ffffff";
        ctx.fillRect(0,0,canvas.width, canvas.height);

        if(this.options.bg&&picture!=="http://saleadmin.uniceramics.com.cn/storage/showroom_category/"){
          //因为服务器有跨域问题，此处从本地设置图片
          // let bgurl = '';
          // console.log(store.state.selectIndex===1)
          // switch(store.state.selectIndex){
          //   case 0:
          //     bgurl = require('@/assets/bg/bg01.gif')
          //     break;
          //   case 1:
          //     bgurl=require('@/assets/bg/bg02.gif')
          //     break;
          //   case 2:
          //     bgurl=require('@/assets/bg/bg03.gif')
          //     break;
          //   case 3:
          //     bgurl=require('@/assets/bg/bg04.gif')
          //     break;
          //   case 4:
          //     bgurl=require('@/assets/bg/bg05.gif')
          //     break;
          //   case 5:
          //     bgurl=require('@/assets/bg/bg06.gif')
          //     break;
          //   case 6:
          //     bgurl=require('@/assets/bg/bg07.gif')
          //     break;
          //   case 7:
          //     bgurl=require('@/assets/bg/bg08.gif')
          //     break;
          //   case 8:
          //     bgurl=require('@/assets/bg/bg09.gif')
          //     break;
          //   case 9:
          //     bgurl=require('@/assets/bg/bg10.gif')
          //     break;
          //   default:
          //     break;
          // }
          // console.log(bgurl);
          let bgurl = getBackgroundByIndex(store.state.selectIndex*1);

          let bgImgObj = await loadImage(bgurl);
          ctx.drawImage(bgImgObj, 0, 0, bgImgObj.width, bgImgObj.height, cp[3], cp[0], canvas.width-cp[1]-cp[3], canvas.height-cp[0]-cp[2]);
          this.options.grid && this.drawGrid();
          this.drawRect();
          this.drawTitle();
          this.options.ruler && this.drawPaddingRuler();

          // img.src = picture;
        }else{
          this.options.bg = false;
          this.options.grid && this.drawGrid();
          ctx.beginPath();
          ctx.lineWidth="3";
          ctx.strokeStyle="#000000";
          ctx.rect(cp[3], cp[0], canvas.width-cp[1]-cp[3], canvas.height-cp[0]-cp[2]);
          ctx.stroke();
          this.drawTitle();
          this.drawRect();
          this.options.ruler && this.drawPaddingRuler();
        }
      },
      async drawTitle(){
        const {canvas,ctx} = this;
        const {name, width, height,picture} = this.selectCategory;
        const cp = this.cpadding;
        // console.log(this.selectCategory);
        const leftRight = this.options.bg?cp[3]+80:cp[3]; //文字左右边距
        const area = Math.floor(width*height/1000000*10)/10+"㎡";

        let logoImgObj = await loadImage(require('@/assets/logo_text.png'));
        ctx.drawImage(logoImgObj, 195, 185, 820, 205);

        ctx.beginPath();
        ctx.moveTo(1156,380);
        ctx.lineTo(3400,380);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#111";
        ctx.stroke();

        ctx.fillStyle="#fe0000";
        ctx.font = "90px Microsoft YaHei"
        const nameWidth = ctx.measureText(name);
        ctx.fillRect(1160, 200, nameWidth.width+70, 90+70);
        ctx.fillStyle="#fff";
        ctx.textBaseline = 'top';
        ctx.fillText(name,1160+nameWidth.width/2+35,200+44);

        
        let areaImgObj = await loadImage(getAreaImgByIndex(store.state.selectIndex*1));
        ctx.drawImage(areaImgObj, 3518, 140, 248, 247);

        

        // ctx.fillStyle = "#000";
        // ctx.textAlign = "left";
        // ctx.textBaseline = "top";
        // if(picture!=="http://saleadmin.uniceramics.com.cn/storage/showroom_category/"){
        //   ctx.font = "55px bold Arial";
        //   ctx.fillText("佛山潭洲国际陶瓷展", leftRight, 170);
        //   ctx.font = "40px bold Arial";
        //   ctx.fillText("展览地址: 中国 佛山 潭洲会展中心", leftRight, 255);
        // }
        // ctx.font = "90px bold Arial";
        // ctx.textAlign = "end"
        // ctx.fillText(name, canvas.width-leftRight, 120);
        // ctx.font = "40px bold Arial";
        // ctx.fillText("展馆面积: "+area, canvas.width-leftRight, 240);
      },
      drawRect(){
         const {canvas,ctx} = this;
         const cp = this.cpadding;
         const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
       
         this.lists.forEach((item)=>{
          const {width,height,top,left,status,title} = item;
          ctx.beginPath();
          ctx.lineWidth="4";
          ctx.strokeStyle="#ed1f24";
          let rleft = left*radio+this.innerLeft+cp[3];
          let rtop = top*radio+this.innerTop+cp[0];
          let rheight = height*radio;
          let rwidth = width*radio;
          ctx.rect(rleft, rtop, rwidth, rheight);
          ctx.stroke();
          // console.log(item);

          if(this.selectCategory.code!==11&&this.selectCategory.code!==12){
            this.options.ruler && this.drawRuler(item);
          }

          ctx.fillStyle="#fff";
          ctx.fillRect(rleft, rtop, rwidth, rheight);
          
          //已经完成
          if(this.options.status&&item.status===2){
            ctx.fillStyle="#ffadad";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
          }
          //锁定中
          if(this.options.status&&item.status===3){
            ctx.fillStyle="#dddddd";
            ctx.fillRect(rleft, rtop, rwidth, rheight);
          }


          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          //长条形展位
          if(height<=4000&&width>20000){
            ctx.font = "28px bold Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +width/1000+"×"+height/1000+"="+width*height/1000000;
            if(this.options.status && !!item.order_form.customer_brand){
              let maxFontSize = Math.floor(rwidth/28)-text.length;
              let company = item.order_form.customer_brand
              let subtext = company.length<maxFontSize?company:company.substr(0,maxFontSize-1)+'...';
              text = title+ "　" + subtext + "　" + width/1000+"×"+height/1000+"="+width*height/1000000;
            }
            ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            return false;
          }

          //长条形展位  竖向
          if(width<=4000&&height>20000){
            ctx.font = "28px bold Arial";
            ctx.fillStyle = "#000";
            let text = title+ "　" +width/1000+"×"+height/1000+"="+width*height/1000000;
            if(this.options.status && this.options.brand && !!item.order_form.customer_brand){
              let maxFontSize = Math.floor(rheight/28)-text.length;
              let company = item.order_form.customer_brand
              let subtext = company.length<maxFontSize?company:company.substr(0,maxFontSize-1)+'...';
              text = title+ "　" + subtext + "　" + width/1000+"×"+height/1000+"="+width*height/1000000;
            }
            // ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            ctx.save();
            ctx.translate(rleft+rwidth/2,rtop+rheight/2);
            ctx.rotate(Math.PI/180*90);
            ctx.textBaseline = 'middle';
            ctx.fillText(text,0,0);
            ctx.restore();
            // y+=ctx.measureText(str).width+letterSpacing;
            // ctx.rotate(Math.PI/180*90);
            ctx.restore();
            return false;
          }

          //宽高都大于6m的展位
          if(height>6000&&width>6000){
            let offset = 30;
            if(this.options.status && this.options.brand && !!item.order_form.customer_brand){
              offset = 50
              ctx.font = "bold 30px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(item.order_form.customer_brand, rwidth, 30);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            ctx.font = "bold 40px Arial";
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset+2);
            ctx.font = "bold 30px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset);
            return false;
          }

          //宽度为3米，高度小于20m的小展位
          if(height<20000&&height>60000&&width===3000){
            let offset = 20;
            if(this.options.status && this.options.brand && !!item.order_form.customer_brand){
              offset = 35
              ctx.font = "bold 18px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(item.order_form.customer_brand, rwidth, 18);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            ctx.font = "bold 20px Arial";
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
            ctx.font = "bold 18px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset+4);
            return false;
          }

          if(height<=4000&&width<=4000){
            let offset = 10;
            ctx.font = "bold 20px Arial";
             if(this.selectCategory.width>140000){
              ctx.font = "bold 18px Arial";
            }
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
            ctx.font = "bold 20px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset+5);
            return false;
          }

          // 高度或宽度小于6m的中小型展位
          if(height<=6000||width<=6000){
            let offset = 16;
            if(this.options.status && !!item.order_form.customer_brand){
              offset = 30;
              ctx.font = "bold 22px Arial";
              ctx.fillStyle = "#333";
              let text = this.splitText(item.order_form.customer_brand, rwidth, 24);
              ctx.fillText(text, rleft+rwidth/2, rtop+rheight/2);
            }
            ctx.font = "bold 24px Arial";
             if(this.selectCategory.width>140000){
              ctx.font = "bold 18px Arial";
            }
            ctx.fillStyle = "#000";
            ctx.fillText(title, rleft+rwidth/2, rtop+rheight/2-offset);
            ctx.font = "bold 18px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(width/1000+"×"+height/1000+"="+width*height/1000000, rleft+rwidth/2, rtop+rheight/2+offset);
            return false;
          }
         })
      },
      drawRuler(rect){
        const {canvas,ctx} = this;
        const {width,height,top,left,status,title} = rect;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;

        let rleft = Math.round(left*radio+this.innerLeft+cp[3]);
        let rtop = Math.round(top*radio+this.innerTop+cp[0]);
        let rheight = Math.round(height*radio);
        let rwidth = Math.round(width*radio);

        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#666';
        ctx.font = "22px bold Arial";
        ctx.fillStyle = "#333";
        ctx.textBaseline = 'middle';
       
        //画左侧标尺
        // console.log(rleft-20,rtop,rleft,rtop+rheight) //矩形左上角，右下角顶点
        let offset = 20;
        if(this.options.grid){
          offset=1000*radio;
        }
        if(rect.height>3000&&this.checkCross({left:rleft-21, top:rtop, width:20, height: rheight})){
          ctx.save(); 
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft-offset,rtop);
          ctx.lineTo(rleft-offset,rtop+rheight);
          ctx.moveTo(rleft-offset-5, rtop);
          ctx.lineTo(rleft-5, rtop);
          ctx.moveTo(rleft-offset-5, rtop+rheight);
          ctx.lineTo(rleft-5, rtop+rheight);
          ctx.stroke();
          ctx.restore();
          // 画箭头
          ctx.moveTo(rleft-offset-4,rtop-4);
          ctx.lineTo(rleft-offset+4,rtop+4);
          ctx.moveTo(rleft-offset-4,rtop+rheight-4);
          ctx.lineTo(rleft-offset+4,rtop+rheight+4);
          ctx.stroke();
          //画文字
          ctx.save();
          ctx.translate(rleft-offset-15,rtop+rheight/2);
          ctx.rotate(Math.PI/2);
          ctx.fillText(height,0,0);
          ctx.restore();
        }
        

        if(rect.width>3000&&top*radio<this.innerHeight/2 && this.checkCross({left:rleft, top:rtop+rheight+5, width:rwidth, height: 20})){
          // console.log(title+"----上半部分展位");
          //画下面侧标尺
          ctx.save();
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft,rtop+rheight+offset);
          ctx.lineTo(rleft+rwidth, rtop+rheight+offset);
          ctx.moveTo(rleft,rtop+rheight+5);
          ctx.lineTo(rleft, rtop+rheight+offset+5);
          ctx.moveTo(rleft+rwidth,rtop+rheight+5);
          ctx.lineTo(rleft+rwidth, rtop+rheight+offset+5);
          ctx.stroke();
          
          // 画箭头
          ctx.moveTo(rleft+4,rtop+rheight+offset-4);
          ctx.lineTo(rleft-4,rtop+rheight+offset+4);
          ctx.moveTo(rleft+rwidth+4, rtop+rheight+offset-4);
          ctx.lineTo(rleft+rwidth-4, rtop+rheight+offset+4);
          ctx.stroke();
          ctx.restore();
          //画文字
          ctx.fillText(width,rleft+rwidth/2,rtop+rheight+offset+16);
  
        }

        if(rect.width>3000&&top*radio>this.innerHeight/2 && this.checkCross({left:rleft, top:rtop-offset-10, width:rwidth, height: 20})){
          // console.log(title+"----下半部分展位");
          //画下面侧标尺
          ctx.save();
          ctx.translate(0.5,0.5);
          ctx.beginPath();
          ctx.moveTo(rleft,rtop-offset);
          ctx.lineTo(rleft+rwidth, rtop-offset);
          ctx.moveTo(rleft,rtop-5);
          ctx.lineTo(rleft, rtop-offset-5);
          ctx.moveTo(rleft+rwidth,rtop-5);
          ctx.lineTo(rleft+rwidth, rtop-offset-5);
          ctx.stroke();
          ctx.restore();
          // 画箭头
          ctx.moveTo(rleft+4,rtop-offset-4);
          ctx.lineTo(rleft-4,rtop-offset+4);
          ctx.moveTo(rleft+rwidth+4, rtop-offset-4);
          ctx.lineTo(rleft+rwidth-4, rtop-offset+4);
          ctx.stroke();
          //画文字
          ctx.fillText(width,rleft+rwidth/2,rtop-offset-12);
        }

      },
      checkCross(rect){
        let bool = true;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;

        for(let i=0; i<this.lists.length; i++){
          const {width,height,top,left,status,title} = this.lists[i];
          let r = {
            left:left*radio+this.innerLeft+cp[3],
            top:top*radio+this.innerTop+cp[0],
            height:height*radio,
            width:width*radio
          }
          if(this.isCross(rect,r)){
            bool = false;
            break;
          }
        }
        return bool;
      },
      isCross(rect1,rect2){
        let lux = Math.max(rect1.left, rect2.left);
        let luy = Math.max(rect1.top, rect2.top);
        let rdx = Math.min(rect1.left+rect1.width, rect2.left+rect2.width);
        let rdy = Math.min(rect1.top+rect1.height, rect2.top+rect2.height);
        // return (lux<rdx && luy<rdy);
        //此处允许有1个像素误差，某些展位顶点有重合，不认为展位有重合
        return (lux+1<rdx && luy+1<rdy); 
      },
      drawPaddingRuler(){
        const {canvas,ctx} = this;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;
        const org = {x:cp[3]+this.innerLeft, y:cp[0]+this.innerTop};

        //根据展位内边距计算边距标尺
        const sumWidth = this.selectCategory.width*radio;
        const sumHeight = this.selectCategory.height*radio;
        const top = this.selectCategory.top_padding*radio;
        const bottom = this.selectCategory.bottom_padding*radio;
        const left = this.selectCategory.left_padding*radio;
        const right = this.selectCategory.right_padding*radio;
        const width = sumWidth - left -right;
        const height = sumHeight -top -bottom;

        if(top===0&&left===0){
          return false;
        }

        ctx.font = "22px bold Arial";
        ctx.fillStyle = "#333";
        ctx.strokeStyle = '#666';
        ctx.textBaseline = 'top';
        ctx.textAlign = "center";
        // ctx.setLineDash([3]);
        ctx.translate(0.5,0.5);
        ctx.beginPath();

        /* 横向 */
        //左上
        ctx.moveTo(org.x, org.y+top);
        ctx.lineTo(org.x+left, org.y+top);
        ctx.fillText(this.selectCategory.left_padding, org.x+left/2, org.y+top+5)
        // 画箭头
        ctx.moveTo(org.x+4, org.y+top-4);
        ctx.lineTo(org.x-4, org.y+top+4);
        ctx.moveTo(org.x+left+4, org.y+top-4);
        ctx.lineTo(org.x+left-4, org.y+top+4);
        ctx.stroke();

        //左下
        ctx.moveTo(org.x, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+left, org.y+sumHeight-bottom);
        ctx.fillText(this.selectCategory.left_padding, org.x+left/2, org.y+sumHeight-bottom-23)
        // 画箭头
        ctx.moveTo(org.x+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x-4, org.y+sumHeight-bottom+4);
        ctx.moveTo(org.x+left+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+left-4, org.y+sumHeight-bottom+4);
        ctx.stroke();

        // 右上
        ctx.moveTo(org.x+sumWidth-right, org.y+top);
        ctx.lineTo(org.x+sumWidth, org.y+top);
        ctx.fillText(this.selectCategory.right_padding, org.x+sumWidth-right/2, org.y+top+5)
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+top-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+top+4);
        ctx.moveTo(org.x+sumWidth+4, org.y+top-4);
        ctx.lineTo(org.x+sumWidth-4, org.y+top+4);
        ctx.stroke();

        // 右下
        ctx.moveTo(org.x+sumWidth-right, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+sumWidth, org.y+sumHeight-bottom);
        ctx.fillText(this.selectCategory.right_padding, org.x+sumWidth-right/2, org.y+sumHeight-bottom-20)
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+sumHeight-bottom+4);
        ctx.moveTo(org.x+sumWidth+4, org.y+sumHeight-bottom-4);
        ctx.lineTo(org.x+sumWidth-4, org.y+sumHeight-bottom+4);
        ctx.stroke();

        /* 纵向 */
        //左上
        ctx.moveTo(org.x+left, org.y);
        ctx.lineTo(org.x+left, org.y+top);
        ctx.save();
        ctx.translate(org.x+left+20, org.y+top/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+left+4,org.y-4);
        ctx.lineTo(org.x+left-4, org.y+4);
        ctx.stroke();

        //左下
        ctx.moveTo(org.x+left, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+left, org.y+sumHeight);
        ctx.save();
        ctx.translate(org.x+left+23, org.y+sumHeight-bottom/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+left+4,org.y+sumHeight-4);
        ctx.lineTo(org.x+left-4, org.y+sumHeight+4);
        ctx.stroke();

        //右上
        ctx.moveTo(org.x+sumWidth-right, org.y);
        ctx.lineTo(org.x+sumWidth-right, org.y+top);
        ctx.save();
        ctx.translate(org.x+sumWidth-right-5, org.y+top/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.top_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4,org.y-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+4);
        ctx.stroke();

        //右下
        ctx.moveTo(org.x+sumWidth-right, org.y+sumHeight-bottom);
        ctx.lineTo(org.x+sumWidth-right, org.y+sumHeight);
        ctx.save();
        ctx.translate(org.x+sumWidth-right-5, org.y+sumHeight-bottom/2);
        ctx.rotate(Math.PI/2);
        ctx.fillText(this.selectCategory.bottom_padding,0,0);
        ctx.restore();
        // 画箭头
        ctx.moveTo(org.x+sumWidth-right+4, org.y+sumHeight-4);
        ctx.lineTo(org.x+sumWidth-right-4, org.y+sumHeight+4);
        ctx.stroke();
      },
      drawGrid(){
        // let loadingInstance = Loading.service({
        //   lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.5)'
        // });

        const {canvas,ctx} = this;
        const radio =  this.innerWidth/this.selectCategory.width;  //真实长度和像素比例
        const cp = this.cpadding;
        const sumWidth = this.selectCategory.width;
        const sumHeight = this.selectCategory.height;

        const top = this.selectCategory.top_padding;
        const left = this.selectCategory.left_padding;

        const org = {x:cp[3]+this.innerLeft+left%1000*radio, y:cp[0]+this.innerTop+top%1000*radio};

        let w = sumWidth - left%1000;
        let h = sumHeight - top%1000;
        let margin = 1000*radio;
        // ctx.save();
        ctx.translate(0.5,0.5);
        ctx.strokeStyle = '#bbb';
        ctx.setLineDash([2,6]);
        //纵向线
        for(let i = 0; i <= w/1000; i++){
          ctx.moveTo(org.x+i*margin, org.y - top%1000*radio);
          ctx.lineTo(org.x+i*margin, org.y+sumHeight*radio- top%1000*radio);
        }
        //横向线
        for(let j = 0; j <= h/1000; j++){
          ctx.moveTo(org.x - left%1000*radio, org.y+j*margin);
          ctx.lineTo(org.x+sumWidth*radio-left%1000*radio, org.y+j*margin);
        }
        ctx.stroke();
        ctx.setLineDash([0]);
        // ctx.restore();
        // loadingInstance.close();
      },
      bgSwitch(){
        this.initCalc();
      },
      rulerSwitch(){
        this.initCalc();
      },
      gridSwitch(){
        this.initCalc();
      },
      splitText(text, maxwidth, fontsize){
        if(text.length*fontsize<=maxwidth){
          return text;
        }
        return text.substr(0,Math.floor(maxwidth/fontsize)-1)+'...';
      },
      download(){
        const canvas = this.canvas;
        let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
    
        let link = document.getElementById('link');
        link.setAttribute('download', this.selectCategory.name+'.png');
        link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        link.click();
      },
      close(){
        this.show = false;
        this.options.grid=true;
        this.options.bg=true;
        this.options.status=true;
        this.options.brand=true;
        this.options.ruler=true;
        // this.cpadding = [480,240,120,240]
        this.$emit('update:exportVisible', false)
        this.tempRectList = [];
      },
      changeToolType(type){
        this.toolType = type;
      },
    },
    watch:{
      exportVisible(val){
        console.log(this.selectCategory)
        this.show = val;
        this.$nextTick(()=>{
          this.initCalc();
        })
      },
      selectCategory(val){
        this.$nextTick(()=>{
          // this.initCalc();
        });
      }
    }
  }
</script>

<style scoped>
.el-dialog .el-dialog__body{padding:10px 20px 30px 20px;}

.headbox{width:100%; display: flex; justify-content: center; align-items: center; margin-top:-20px;}
.headbox .item{flex:0 0 auto; line-height: 21px; margin:0 5px;}
.headbox .item>*{vertical-align: middle;}

.headbox .item.download{cursor: pointer; color:#666; margin:0 10px;}
.headbox .item.download:hover{color:#247fce;  text-decoration: underline;}

.export-content{border:1px dashed #ccc; margin-top:10px; display:flex; background: rgba(255,140,0,.1); user-select: none;
position: absolute; top:100px; bottom:0; right: 0; left:0; }

#exportCanvas{margin:auto;}

#link{display:none;}

canvas{transition-duration: 300ms; max-width: 95%; max-height:95%;}

</style>